@use '../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

//Mobile-first layout

.wrapper {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;

  .wrapper-title {
    font-family: MonzoSansDisplay;
    font-size: $font-size-mobile-title;
    margin-bottom: $spacing-xs;
    margin-left: $spacing-xs;
  }

  .wrapper-content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .wrapper-settled-title {
    font-family: MonzoSansDisplay;
    font-size: $font-size-large;
    font-weight: 600;
    margin: $spacing-xs;
    margin-left: $spacing-s;
  }

  .no-active-tabs {
    padding: $spacing-s;
    border-radius: 10px;
    background-color: $color-white;
    font-weight: 600;
    margin: $spacing-s 0;
  }
}

@include mq($from: $desktop) {
  .wrapper {
    border-radius: $border-radius-large;

    .wrapper-title {
      font-size: $font-size-desktop-title;
      font-weight: 800;
    }
  }
}
